
<template>
<b-container fluid>
  <bo-page-title />

  <b-card-header v-if="isList">
    <div class="d-flex justify-content-between">
      <b-button-group>
        <b-button size="sm" @click="setActiveMenu('')" :variant="active_filter == '' ? 'info' : 'outline-info'">All ({{total_all}})</b-button>
        <b-button size="sm" @click="setActiveMenu('schedule')" :variant="active_filter == 'schedule' ? 'info' : 'outline-info'">Scheduled ({{total_schedule}})</b-button>
        <b-button size="sm" @click="setActiveMenu('done')" :variant="active_filter == 'done' ? 'info' : 'outline-info'">Finished ({{total_finish}})</b-button>
        <b-button size="sm" @click="setActiveMenu('cancel')" :variant="active_filter == 'cancel' ? 'info' : 'outline-info'">Cancelled ({{total_cancel}})</b-button>
      </b-button-group>

    </div>
  </b-card-header>

  <b-card v-if="isList" no-body>
    <b-card-header>
      <b-row>
        <b-col>
          <b-form-row>
            <b-col md="4">
              <date-picker value-type="format" format="YYYY-MM-DD" range prefix-class="crm" v-model="dateFilter" v-b-tooltip.hover="'Filter by Date'" />
            </b-col>
          </b-form-row>
        </b-col>
        <b-col cols="auto">
          <SearchInput :value.sync="filter.search" @search="doFilter" />
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body class="p-0">
      <b-table
        :fields="fields"
        :items="dataList||[]"
        :primary-key="idKey"
        :busy="!dataList"
        responsive
        show-empty
        bordered
        striped
      >
        <template #empty>
          <div class="text-center">
            <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
            <h4 align="center"><span v-if="Object.keys($route.query).length">No search results found</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(number)="v">
          {{(data.per_page*(data.current_page-1))+v.index+1}}
        </template>
        <template #cell(tss_date)="data">
          {{data.value | moment('LLL')}}
          <br />
          <b-badge variant="danger" v-if="data.item.tss_status == 'schedule' && isBeforeNow(data.item.tss_date)">
            LATE
          </b-badge>
        </template>
        <template #cell(tp_client_name)="data">
          <div><small>{{ data.item.tp_code }}</small></div>
          <div>{{ data.value }} - {{ data.item.tp_client_type == 'residencial' ? 'Residential' : 'Company' }}</div>
          <a v-if="data.item.tp_client_email" :href="'mailto: '+data.item.tp_client_email" class="contact-info"><i class="ti-email"></i><span>{{ data.item.tp_client_email }}</span></a>
          <a v-if="data.item.tp_client_phone" :href="'tel: '+data.item.tp_client_phone" class="contact-info"><i class="icon-phone"></i><span>{{ data.item.tp_client_phone }}</span></a>
        </template>
        <template #cell(tss_address)="data">
          <div><small>PIC: {{ data.item.tss_pic_name }}</small></div>
          <div>{{ data.value }}</div>
        </template>
        <template #cell(tss_status)="data">
          <b-badge
            :variant="surveyBadge[data.value].color"
          >
            {{ surveyBadge[data.value].word }}
          </b-badge>
        </template>
        <template
          #cell(action)="data"
        >
          <div class="d-flex align-items-center" v-if="data.item.tss_status == 'schedule'">
            <b-button
                class="btn-icon"
                variant="success"
                v-b-tooltip.hover="'Add Survey Result'"
                v-if="moduleRole('add_survey_result')"
                @click="openModalSurveyResult(data.item)"
              >
                <i class="fas fa-file-alt"></i>
              </b-button>
              <b-button
                class="btn-icon"
                variant="outline-info"
                v-b-tooltip.hover="'View Survey Details'"
                :to="{ name: $route.name, params: { pageSlug: 'detail', pageId: data.item.tp_code } }"
              ><i class="fas fa-eye"></i>
              </b-button>
              <b-button
                class="btn-icon"
                variant="outline-warning"
                v-b-tooltip.hover="'Edit Survey'"
                v-if="moduleRole('edit')"
                @click="editSurvey(data.item)"
              ><i class="fas fa-pencil-alt"></i>
              </b-button>
              <b-button
                class="btn-icon"
                variant="outline-danger"
                v-if="moduleRole('cancel_schedule')"
                v-b-tooltip.hover="'Cancel Survey'"
                @click="cancelSurvey(data.item.tss_id)"
              ><i class="fas fa-times"></i>
              </b-button>
          </div>
          <div class="d-flex align-items-center" v-else-if="data.item.tss_status == 'done' || data.item.tss_status == 'cancel'">
            <b-button
                class="btn-icon"
                variant="outline-info"
                v-b-tooltip.hover="'View Survey Details'"
                :to="{ name: $route.name, params: { pageSlug: 'detail', pageId: data.item.tp_code } }"
              ><i class="fas fa-eye"></i>
              </b-button>
          </div>
        </template>
      </b-table>
    </b-card-body>
    <b-card-footer>
      <template v-if="(dataList||[]).length&&data.total>10">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <label for="perPage">Show</label>
              <b-form-select
                size="sm"
                id="perPage"
                class="w-auto mx-50"
                v-model="perPage"
                :options="Config.dataShownOptions"
              />
            <label for="perPage">items per page</label>
          </div>
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </div>
      </template>
    </b-card-footer>
  </b-card>

  <template v-else>
    <Detail v-bind="dataDetail" />
  </template>


  <!-- Survey result -->
  <b-modal
    id="surveyResult"
    title="Add Survey Result"
    centered
    no-close-on-backdrop
  >
    <validation-observer v-slot="{ handleSubmit }" ref="VFormSurveyResult">
      <b-form @submit.prevent="handleSubmit(addSurveyResult())">
        <b-form-row>
          <b-col md="6">
            <b-form-group label="Survey Date" label-for="monDate" label-class="mb-1">
              <span>{{ dataModal.tss_date | moment('LLL') }}</span>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="PIC" label-for="monPic" label-class="mb-1">
              <span>{{dataModal.tss_pic_name || ''}}</span>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col md="12">
            <b-form-group>
              <b-form-checkbox v-model="dataModal.reschedule">Reschedule Survey ?</b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="dataModal.reschedule">
            <b-form-group label="Reschedule Date" label-for="rescheduleDate">
              <date-picker type="datetime" value-type="format" format="YYYY-MM-DD HH:mm:ss" id="rescheduleDate" prefix-class="crm" v-model="dataModal.tss_reschedule_date" :disabled-date="notBeforeToday" />
            </b-form-group>
            <VValidate name="Reschedule Date" rules="required" v-model="dataModal.tss_reschedule_date" />
          </b-col>
        </b-form-row>
        <template v-if="!dataModal.reschedule">
          <b-form-group label="Survey Result" label-for="monResult">
            <b-form-textarea id="monResult" rows="3" placeholder="e.g. pemasangan sudah berjalan 50%" v-model="dataModal.tss_result" />
            <VValidate name="Survey Result" rules="required" v-model="dataModal.tss_result" />
          </b-form-group>
          <b-form-group label-for="monPhoto">
            <template #label>Photo <small>(Optional)</small></template>
            <Uploader v-model="dataModal.tss_image" :usePreview="true" type="survey_result"/>
          </b-form-group>
          <b-form-checkbox v-model="dataModal.tss_next">Schedule Next Survey</b-form-checkbox>
          <div v-if="dataModal.tss_next">
            <hr>
            <b-form-row>
              <b-col md=12>
                <b-form-group label="Survey Schedule" label-for="surveyDate">
                  <date-picker type="datetime" value-type="format" format="YYYY-MM-DD HH:mm:ss" id="surveyDate" prefix-class="crm" v-model="dataModal.tss_next_date" :disabled-date="notBeforeTodaySurvey" />
                </b-form-group>
                  <VValidate name="Survey Schedule" rules="required" v-model="dataModal.tss_next_date" />
              </b-col>

              <b-col md=12>
                <b-form-group label="Surveyor" label-for="folSurveyor">
                  <v-select id="folSurveyor" v-model="dataModal.tss_pic" :options="mrSurveyor" label="label" :reduce="v => String(v.value)" placeholder="Select Surveyor" />
                </b-form-group>
                  <VValidate name="Surveyor" rules="required" v-model="dataModal.tss_pic" />
              </b-col>

              <b-col md="12">
                <b-form-group label="Note" label-for="proSurveyNote">
                  <b-form-textarea id="proSurveyNote" rows="3" v-model="dataModal.tss_note" />
                  <VValidate name="Note" rules="" v-model="dataModal.tss_note" />
                </b-form-group>
              </b-col>

            </b-form-row>
          </div>
          <div class="mt-2" v-else>
            <label for="">Next Status</label>
            <b-form-radio-group :options="statusSurveyResult" v-model="dataModal.next_status" />
            <VValidate name="Status" rules="required" v-model="dataModal.next_status" />
          </div>
          <b-form-row v-if="!dataModal.tss_next" class="mt-3">
            <template v-if="dataModal.next_status == 'FU'">
              <b-col md="12">
                <b-form-group label="Next Follow Up Schedule">
                  <date-picker value-type="format" format="YYYY-MM-DD" id="proFollow" prefix-class="crm" v-model="dataModal.tfu_date" :disabled-date="notBeforeToday" />
                </b-form-group>
              </b-col>
            </template>
            <template v-else-if="dataModal.next_status == 'C'">
              <b-col md="6">
                <b-form-group label="Project Worth" label-for="proPrice">
                  <b-input-group prepend="Rp.">
                    <money id="proPrice" v-model="dataModal.tp_total_price" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                  </b-input-group>
                  <VValidate name="Project Worth" rules="required|min_value:0" v-model="dataModal.tp_total_price" />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Down Payment (DP)" label-for="proDP">
                  <b-input-group prepend="Rp.">
                    <money id="proDP" @input="setPricePartner(dataModal)" v-model="dataModal.tp_dp" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                  </b-input-group>
                  <VValidate name="Down Payment (DP)" :rules="{ required: true, min_value: 0, max_value: dataModal.tp_total_price }" v-model="dataModal.tp_dp" />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <div class="border p-3 mb-3">
                  <h5 class="font-weight-bold">Down Payment Shares</h5>
                  <b-form-row>
                    <b-col md="6">
                      <b-form-group label="Company Share" label-for="proCompShare" class="mb-0">
                        <b-input-group prepend="Rp.">
                          <money id="proCompShare" @input="setPricePartner(dataModal)" v-model="dataModal.tp_price_bu" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                        </b-input-group>
                        <VValidate name="Company Share" :rules="{ required: true, min_value: 0, max_value: dataModal.tp_dp }" v-model="dataModal.tp_price_bu" />
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="Partner Share" label-for="proPartShare" class="mb-0">
                        <b-input-group prepend="Rp.">
                          <money id="proPartShare" disabled v-model="dataModal.tp_price_partner" v-bind="maskCurrency" placeholder="cth: 600.00" class="form-control"></money>
                        </b-input-group>
                        <VValidate name="Company Share" :rules="{ required: true, min_value: 0, max_value: dataModal.tp_dp }" v-model="dataModal.tp_price_partner" />
                      </b-form-group>
                    </b-col>
                  </b-form-row>
                </div>
              </b-col>
              <b-col md="6">
                <b-form-group label="Partner Assignment" label-for="proPartner">
                  <v-select id="proPartner" :options="mrPartner" :reduce="v => v.mp_id" v-model="dataModal.tp_partner" label="mp_name" placeholder="Choose a partner to be assigned" />
                  <VValidate name="Partner Assignment" rules="required" v-model="dataModal.tp_partner" />
                </b-form-group>
              </b-col>
              <div class="w-100"></div>
              <b-col md="6">
                <b-form-group label="Project Start Date" label-for="proProjectDate">
                  <date-picker v-model="dataModal.tp_start_date" value-type="format" format="YYYY-MM-DD" id="proProjectDate" prefix-class="crm" :disabled-date="notBeforeToday" />
                </b-form-group>
                <VValidate name="Project Start Date" rules="required" v-model="dataModal.tp_start_date" />
              </b-col>
              <b-col md="6">
                <b-form-group label="Project End Date" label-for="proProjectDate">
                  <date-picker v-model="dataModal.tp_end_date" value-type="format" format="YYYY-MM-DD" id="proProjectDate" prefix-class="crm" :disabled-date="notBeforeToday" />
                </b-form-group>
                <VValidate name="Project End Date" rules="required" v-model="dataModal.tp_end_date" />
              </b-col>
              <b-col md="6">
                <b-form-group label="Status Project" label-for="customerStatus">
                  <b-form-radio-group id="customerStatus" :options="closingStatus" v-model="dataModal.tp_status_customer" />
                  <VValidate name="Status Project" rules="required" v-model="dataModal.tp_status_customer" />
                </b-form-group>
              </b-col>
            </template>
            <template v-else-if="dataModal.next_status == 'R'">
              <b-col md="10">
                <b-form-group label-for="proReason">
                  <template #label>
                    Rejection Reason <small>(Optional)</small>
                  </template>
                  <b-form-textarea v-model="dataModal.tp_reject_reason" rows="3" placeholder="e.g. Customer masih belum yakin untuk membuat furnitur custom" />
                </b-form-group>
              </b-col>
            </template>
          </b-form-row>
        </template>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <b-button size="md" variant="transparent" @click="closeModal('surveyResult')">Cancel</b-button>
      <b-button size="md" variant="info" @click="addSurveyResult()">Submit Result</b-button>
    </template>
  </b-modal>

  <b-modal
    id="updateSurvey"
    title="Update Survey Schedule"
    centered
    no-close-on-backdrop
  >
    <validation-observer v-slot="{ handleSubmit }" ref="VFormEdit">
      <b-form @submit.prevent="handleSubmit(submitSurveyEdit())">
        <b-form-group label="Customer Name" label-for="proName" label-class="mb-1">
          <span>{{row.tp_client_name}}</span>
        </b-form-group>
        <b-form-row>
          <b-col md="6">
            <b-form-group label="Phone Number" label-for="surPhone" label-class="mb-1">
              <a :href="'tel:'+row.tp_client_phone">
                <i class="fas fa-phone-alt mr-2"></i><span>{{row.tp_client_phone}}</span>
              </a>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group v-if="row.tp_client_email" label="Email" label-for="surEmail" label-class="mb-1">
              <a :href="'mailto:'+row.tp_client_email">
                <i class="fas fa-at mr-2"></i><span>{{row.tp_client_email}}</span>
              </a>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-group label="Address" label-for="surAddress" label-class="mb-1">
          <span>{{row.tp_client_address}}</span>
        </b-form-group>
        <b-form-group label="Survey Schedule" label-for="proSurveySchedule">
          <date-picker type="datetime" value-type="format" format="YYYY-MM-DD HH:mm:ss" id="proSurveySchedule" prefix-class="crm" v-model="row.tss_date" :disabled-date="notBeforeToday" />
          <VValidate name="Survey Schedule" v-model="row.tss_date" rules="required" />
        </b-form-group>
        <b-form-group>
          <b-form-checkbox v-model="row.same_address">Use Customer Address as Survey Address</b-form-checkbox>
        </b-form-group>
        <b-form-group label="Survey Address" label-for="proSurveyAddress" v-if="!row.same_address">
          <b-form-textarea v-model="row.tss_address" id="proSurveyAddress" rows="3" />
          <VValidate name="Survey Address" v-model="row.tss_address" rules="required|min:5" />
        </b-form-group>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <b-button size="md" variant="transparent" @click="closeModal('updateSurvey')">Cancel</b-button>
      <b-button size="md" variant="info" @click="submitSurveyEdit()">Update Survey</b-button>
    </template>
  </b-modal>
</b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen'
import Detail from './Detail.vue'

export default {
  extends: GlobalVue,
  components:{Detail},
  data() {
    return {
      dateFilter: [],
      idKey:'tp_id',
      mrValidation: {},
      statusSurveyResult: [
        { text: 'Follow Up', value: 'FU' },
        { text: 'Closing', value: 'C' },
        { text: 'Reject', value: 'R' },
      ],
      statusPending: [
        { text: 'Survey', value: 'S' },
        { text: 'Closing', value: 'C' },
        { text: 'Reject', value: 'R' },
      ],
      closingStatus: [
        { text: 'Schedule', value: 'S' },
        { text: 'Running', value: 'R' },
      ],
      fields: [
        {
          key: 'number', label: '#', 
        },
        {
          key: 'tp_client_name', label: 'Customer Name',
        },
        {
          key: 'tss_date', label: 'Survey Date',
        },
        {
          key: 'tss_address', label: 'Survey Adress', 
        },
        {
          key: 'tp_keperluan', label: 'Purpose', 
        },
        {
          key: 'tss_status', label: 'Status', 
          },
        {
          key: 'action', 
        },
      ],
      customerExists: false,
      mrClient: [],
      mrPartner: [],
      mrSurveyor: [],
      customerName: '',
      dataModal: {},
      total_schedule: 0,
      total_finish: 0,
      total_all: 0,
      total_cancel: 0,
      active_filter: '',
      dataDetail: {}
    }
  },
  mounted(){
    this.apiGet()
    this.active_filter = this.$route.query.status || ''
    const a = []
    a[0] = this.$route.query['start-date'] || null
    a[1] = this.$route.query['end-date'] || null

    this.$set(this, 'dateFilter', a)
  },
  methods: {
    doFilterDate(newVal){
      this.$set(this.filter, 'start-date', newVal[0] || '')
      this.$set(this.filter, 'end-date', newVal[1] || '')
      this.doFilter()
    },
    isBeforeNow(date){
      return new Date(date).getTime() < new Date().getTime()
    },
    setActiveMenu(status){
      this.active_filter = status

      this.$set(this.filter, 'status', status)
      this.doFilter()
    },
    openModalFollowUp(data){
      this.dataModal = data
      this.row = {}
      this.$set(this.dataModal, 'tfu_status', data.tp_status_prospect)
      this.$bvModal.show('followUpResult')
    },
    openModalSurveyResult(data){
      this.$set(this, 'dataModal', Object.assign({}, data))
      this.row = {}
      this.$bvModal.show('surveyResult')
    },
    openModalPending(data){
      this.$set(this, 'dataModal', Object.assign({}, data))
      this.row = {}
      this.$bvModal.show('modalPending')
    },
    createProspect(value){
      this.customerExists = value
      this.$set(this, 'row', {})
      this.$set(this.row, 'tp_status_prospect', 'FU')
      this.$bvModal.show('newProspect')
    },
    editSurvey(data){
      this.$set(this, 'row', Object.assign({}, data))
      this.$set(this.row, 'edit', true)
      this.$bvModal.show('updateSurvey')
    },
    notBeforeToday(date) {
      return date ?  false : false
      // return date < new Date(new Date().setHours(0, 0, 0, 0))
    },
    notBeforeTodaySurvey(date) {
      return date ?  false : false
      // return date < new Date(this.dataModal.tss_date)
    },
    cancelSurvey(id){
      this.$swal({
        title: 'Cancel this schedule?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
        icon: 'warning',
        cancelButtonText: 'Back',
      }).then(result => {
        if(result.value){
          Gen.apiRest(
            '/do/' + this.modulePage,
            { data: {type: 'cancel', id} },
            'POST'
          ).then(res => {
            if(res){
                this.$swal({
                  title: res.data.message,
                  timer: 1500,
                  icon: 'success',
                }).then(() => {
                  this.apiGet()
                })
            }
          }).catch(() => {
              this.apiGet()
          })
        }
      })
    },
    addSurveyResult(){
      this.$refs.VFormSurveyResult.validate().then(success => {
        if(!success) return

        this.$swal({
          title: 'Add Survey Result...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        Gen.apiRest(
          '/do/' + this.modulePage,
          { data: {type: 'add-surveyResult', ...this.dataModal} },
          'POST'
        ).then(res => {
          if(res){
              this.$swal({
                title: res.data.message,
                text: '',
                timer: 1500,
                icon: 'success',
              }).then(() => {
                this.apiGet()
                this.$bvModal.hide('surveyResult')
              }).catch(() => {
                this.apiGet()
                this.$bvModal.hide('surveyResult')
              })
          }
        })
      })
    },
    submitSurveyEdit(){
      this.$refs.VFormEdit.validate().then(success => {
        if(!success) return

        this.$swal({
          title: 'Update Survey...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        Gen.apiRest(
          '/do/' + this.modulePage,
          { data: {type: 'update-survey', ...this.row} },
          'POST'
        ).then(res => {
          if(res){
              this.$swal({
                title: res.data.message,
                text: '',
                timer: 1500,
                icon: 'success',
              }).then(() => {
                this.apiGet()
                this.$bvModal.hide('updateSurvey')
              }).catch(() => {
                this.apiGet()
                this.$bvModal.hide('updateSurvey')
              })
          }
        })
      })
    },
    closeModal(id, resetRow = true){
      this.$bvModal.hide(id)
      if(resetRow){
        this.$set(this, 'row', {})
      }
    },
    setPricePartner(data){
      if(data['tp_dp'] > 0 && data['tp_price_bu'] > 0){
        const hasil = data['tp_dp'] - data['tp_price_bu']
        data['tp_price_partner'] = hasil < 0 ? 0 : hasil
      }
    }
  },
  computed: {
  },
  watch:{
    $route(){
      this.apiGet()
    },
    'filter'(){
      const a = []
      a[0] = this.filter['start-date'] || null
      a[1] = this.filter['end-date'] || null
      this.$set(this, 'dateFilter', a)
    },
    customerExists(newVal){
      if(!newVal){
          this.$set(this.row, 'tp_client_name', '')
          this.$set(this.row, 'tp_client_type', '')
          this.$set(this.row, 'tp_client_phone', '')
          this.$set(this.row, 'tp_client_email', '')
          this.$set(this.row, 'tp_client_address', '')
      }
      this.$set(this.row, 'customer_exist', newVal)
    },
    customerName(newVal){
      this.$set(this.row, 'tp_client_name', this.mrClient.find(v => v.mc_id == newVal).mc_name)
      if(!this.customerExists) return

      const cust = this.mrClient.find(v => v.mc_id == this.customerName)
      if(cust){
        this.$set(this.row, 'tp_client_type', cust.mc_type)
        this.$set(this.row, 'tp_client_phone', cust.mc_phone)
        this.$set(this.row, 'tp_client_email', cust.mc_email)
        this.$set(this.row, 'tp_client_address', cust.mc_address)
      }
    },
  }
}
</script>